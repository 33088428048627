import { Component } from "react";
import { Link } from "react-router-dom";

class Privacy extends Component {
  render() {
    return (
      <div className="App">
        <div className="footer">
          WeNoLa Studie
        </div>
        <div className="content legal">

          <h3>Datenschutzerklärung</h3>
          <div class="privacy">
                    <div class='legal_paragraph'>
                        <div class='legal_title'>
                        1.	Name und Anschrift der für die Verarbeitung Verantwortlichen
                        </div>
                        <div class='legal_section'>
                            Verantwortlicher im Sinne der EU-Datenschutzgrundverordnung (Art. 24 DSGVO), sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichen Charakter ist:<br/>
                            <br/>
                            Herr Steffen Blass<br/>
                            app affairs GmbH<br/>
                            Klenzestraße 13<br/>
                            80469 München<br/>
                            Tel.: + 49 (0) 89 – 2379 5795<br/>
                            E-Mail: info@app-affairs.de<br/>
                            Website: www.app-affairs.de<br/>
                        </div>
                    </div>
                    <div class='legal_paragraph'>
                        <div class='legal_title'>
                            2.	Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung
                        </div>
                        <div class='legal_section'>
                            Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
                        </div>
                        <div class='legal_section'>
                            <ul class="legal-list">
                                <li>anonymisierte IP-Adresse des anfragenden Rechners,</li>
                                <li>Datum und Uhrzeit des Zugriffs,</li>
                                <li>Name und URL der abgerufenen Datei,</li>
                                <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
                                <li>verwendete Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
                            </ul>
                        </div>
                        <div class='legal_section'>
							Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:<br/>
							<ul class="legal-list">
								<li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
								<li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
								<li>Auswertung der Systemsicherheit und -stabilität sowie</li>
								<li>zu weiteren administrativen Zwecken.</li>
							</ul>
							<br/>
							Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.<br/>
                        </div>
                    </div>

                    <div class='legal_paragraph'>
                        <div class='legal_title'>
                            3. Weitergabe von Daten
                        </div>
                        <div class='legal_section'>
							Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:<br/>
							<br/>
							<ul class="legal-list">
								<li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</li>
								<li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe der Daten haben,</li>
								<li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht sowie</li>
								<li>diese gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.</li>
							</ul>
                        </div>
                    </div>

                    <div class='legal_paragraph'>
                        <div class='legal_title'>
                            4. Betroffenenrechte
                        </div>
                        <div class='legal_section'>
							Sie haben das Recht:<br/>
							<ul class="legal-list">
								<li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung und Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>
								<li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>
								<li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
								<li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
								<li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</li>
								<li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und</li>
								<li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes wenden;</li>
								<li>gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird. Sie haben die Möglichkeit, den Widerspruch telefonisch, per E-Mail, per Telefax oder an unsere zu Beginn dieser Datenschutzerklärung aufgeführte Postadresse formlos mitzuteilen.</li>
							</ul>
                        </div>
                    </div>


                    <div class='legal_paragraph'>
                        <div class='legal_title'>
                            5.	Datensicherheit
                        </div>
                        <div class='legal_section'>
                            Zur Gewährung der Datensicherheit erfolgt die Übertragung der Inhalte unserer Webseite ver-schlüsselt nach dem SSL-Verfahren gemäß dem Stand der Technik. Zur Sicherung der Daten werden von uns und den beauftragten Dienstleistern, mit denen entsprechende vertragliche Vereinbarungen getroffen wurden, geeignete Maßnahmen nach dem Stand der Technik, insbesondere zur Beschränkung des Zugangs zu den Daten, zum Schutz gegen Veränderungen und Verlust, und zur Vertraulichkeit gemäß dem Stand der Technik eingesetzt.<br/>
                        </div>
                    </div>

                    <div class='legal_paragraph'>
                        <div class='legal_title'>
                            6.	Stand und Aktualisierung dieser Datenschutzerklärung
                        </div>
                        <div class='legal_section'>
                            Die Datenschutzerklärung hat den Stand vom 1.5.2022. Wir behalten uns vor, die Datenschutzerklärung zu gegebener Zeit zu aktualisieren, um den Datenschutz zu verbessern und/oder anzupassen.<br/>
                        </div>
                    </div>

                    <div class='legal_paragraph'>
                        <div class='legal_title'>
                            7.	Fragen an die internen Datenschutzbeauftragte
                        </div>
                        <div class='legal_section'>
                            Wenn Sie als Besucher unserer Website Fragen zum Datenschutz haben, schreiben Sie bitte an die für den Datenschutz verantwortliche Person Herrn Steffen Blass eine E-Mail an info@app-affairs.de oder wenden Sie sich direkt unter den in Ziff. 1 dieser Datenschutzerklärung genannten Kontaktdaten an Herrn Steffen Blass.
                        </div>
                    </div>
                </div>

          </div>


        <div className="footer">
          <div className="item"><a href="https://www.app-affairs.com" target="_home">app affairs GmbH</a></div>
          <div className="item center"><Link to="/imprint" target="_new">Impressum</Link></div>
          <div className="item right"><Link to="/privacy" target="_new">Datenschutz</Link></div>
        </div>
      </div>

    )
  }
}

export default Privacy;
