import React, { Component } from "react";
import ProgressBar from "@ramonak/react-progress-bar";


const OtherFreeTextSingleOption = ({question, setData, checked, stringValue, showResult, resultObject}) => {
  return (
    <div>
      <input key='OTHER' type = "radio" name={question.questionId} value='OTHER' checked={checked} onChange={(e) => setData(question.questionId, { singleOption: 'OTHER' })}/>
      &nbsp;Sonstige:&nbsp;
      <input type="text" name={question.questionId} value={stringValue} onChange={(e) => setData(question.questionId, {stringValue: e.target.value, singleOption: 'OTHER'})}/>
    </div>
  )
}

const OtherFreeTextMultiOption = ({question, setData, checked, stringValue, showResult, resultObject}) => {
  return (
    <div>
      <input key='OTHER' type = "checkbox" name={question.questionId} value='OTHER' checked={checked} onChange={(e) => setData(question.questionId, 'OTHER', e.target.checked)}/>
      &nbsp;Sonstige:&nbsp;
      <input type="text" name={question.questionId} value={stringValue} onChange={(e) => setData(question.questionId, 'OTHER', true, e.target.value)}/>
    </div>
  )
}

const OtherFreeTextOption = ({question, setData, getData }) => {
  const isSingleOption = question.type === 2
  const checked = isSingleOption
    ? getData(question.questionId)?.singleOption === 'OTHER'
    : getData(question.questionId)?.multipleOptions?.includes('OTHER')
  const stringValue = getData(question.questionId)?.stringValue || '';

  return (
    isSingleOption
      ? <OtherFreeTextSingleOption question={question} setData={setData} checked={checked} stringValue={stringValue}/>
      : <OtherFreeTextMultiOption question={question} setData={setData} checked={checked} stringValue={stringValue}/>
    )
}


const QuestionTypeFreeTextBox = ({ question, setData, getData, showResult, resultObject }) => {
  return (
    <div>
      <textarea name={question.questionId} onBlur={(e) => setData(question.questionId, {stringValue: e.target.value})} defaultValue={getData(question.questionId)?.stringValue || ''}>

      </textarea>
    </div>
  )
}

const QuestionTypeFreeTextField = ({ question, setData, getData, showResult, resultObject }) => {
  return (
    <div>
      <input type="text" name={question.questionId} placeholder={question.placeholder || ''} onBlur={(e) => setData(question.questionId, {stringValue: e.target.value})}/>
    </div>
  )
}

const SingleOption = ({question, option, index, setData, selectedOption, showResult, resultObject}) => {
  const resultCount = resultObject[question.questionId]?.[option.value];
  const sum = resultObject.sum
  return (
    <div>
      <input key={option._id} type = "radio" checked={selectedOption === option.value} name={question.questionId} value={option.value} onChange={(e) => setData(question.questionId, {singleOption: option.value})}/>
      &nbsp;{option.title}
      {
        showResult && resultCount
          ? <span className="result"> | { resultCount } ({parseInt(resultCount / sum * 100)}%)</span>
          : null
      }
    </div>
  )
}

const QuestionTypeSingleOption = ({ question, optionList, setData, getData, showResult, resultObject }) => {
  const currData = getData(question.questionId)?.singleOption;
  return (
    <div>
      {
      optionList.options.map( (option, index) => {
          return <SingleOption
            key={option._id}
            option={option}
            index={index}
            question={question}
            setData={setData}
            selectedOption={currData}
            showResult={showResult}
            resultObject={resultObject}
            />
        })
      }
      {
        question.showOtherField
        ?<OtherFreeTextOption question = {question} setData={setData} getData={getData} showResult={showResult} resultObject={resultObject}/>
        : null
      }
    </div>
  )
}

const MultiOption = ({question, option, index, setData, selectedOptions, stringValue, showResult, resultObject}) => {
  const resultCount = resultObject[question.questionId]?.[option.value];
  const sum = resultObject.sum
  return (
    <div>
      <input type = "checkbox" key={option._id} checked={selectedOptions.includes(option.value)} name={question.questionId} value={option.value} onChange={(e) => setData(question.questionId, option.value, e.target.checked, stringValue)}/>
      &nbsp;{option.title}
      {
        showResult && resultCount
          ? <span className="result"> | { resultCount } ({parseInt(resultCount / sum * 100)}%)</span>
          : null
      }
    </div>
  )
}

const QuestionTypeMultipleOption = ({ question, optionList, setData, getData, showResult, resultObject }) => {
  const stringValue = getData(question.questionId)?.stringValue || '';
  var chosenValues = getData(question.questionId)?.multipleOptions || []
  const setMultiData = (questionId, value, checked, stringValue) => {
    if (checked && !chosenValues.includes(value)) chosenValues.push(value);
    if (!checked && chosenValues.includes(value)) chosenValues = chosenValues.filter((c) => c !== value);
    if (stringValue && chosenValues.includes('OTHER')) {
      setData(questionId, {multipleOptions: chosenValues, stringValue})
    } else {
      setData(questionId, {multipleOptions: chosenValues})
    }
  }
  return (
    <div>
      {
        optionList.options.map( (option, index) => {
            return <MultiOption key={option._id} option={option} index={index} question={question} setData={setMultiData}
            selectedOptions={chosenValues} stringValue={stringValue}
            showResult = {showResult} resultObject = {resultObject}
            />
        })
      }
      {
        question.showOtherField
        ?<OtherFreeTextOption question = {question} setData={setMultiData} getData={getData} />
        : null
      }

    </div>
  )
}

const QuestionTypeBoolean = ({ question, setData, getData, showResult, resultObject }) => {
  const yesCount = resultObject[question.questionId]?.["yes"] ?? 0;
  const noCount = resultObject[question.questionId]?.["no"] ?? 0;
  const sum = resultObject.sum
  const currVal = getData(question.questionId)?.booleanValue
  return (
    <div>
      <div><input key='y' checked={currVal === true} name = {question.questionId} type = "radio" value={true}  onChange={(e) => setData(question.questionId, {booleanValue: true})}/>&nbsp;Ja
      {
        showResult && yesCount
          ? <span className="result"> | { yesCount } ({parseInt(yesCount / sum * 100)}%)</span>
          : null
      }
      </div>

      <div><input key='n' checked={currVal === false} name = {question.questionId} type = "radio" value={false} onChange={(e) => setData(question.questionId, {booleanValue: false})}/>&nbsp;Nein
      {
        showResult && noCount
          ? <span className="result"> | { noCount } ({parseInt(noCount / sum * 100)}%)</span>
          : null
      }
      </div>
    </div>
  )
}


const Question = ({index, question, optionListMap, setData, getData, showResult, resultObject}) =>  {
  const renderType = (question) => {
    switch(question.type) {
      case 0:
        return <QuestionTypeBoolean question = {question} setData={setData} getData={getData} showResult={showResult} resultObject={resultObject}/>
      case 1:
        return <QuestionTypeFreeTextBox question = {question} setData={setData} getData={getData} showResult={showResult} resultObject={resultObject}/>
      case 2:
        return <QuestionTypeSingleOption question = {question} optionList = {optionListMap[question.optionList]} setData={setData} getData={getData} showResult={showResult} resultObject={resultObject}/>
      case 3:
        return <QuestionTypeMultipleOption question = {question} optionList = {optionListMap[question.optionList]} setData={setData} getData={getData} showResult={showResult} resultObject={resultObject}/>
      case 4:
        return <QuestionTypeFreeTextField question = {question} setData={setData} getData={getData} showResult={showResult} resultObject={resultObject}/>

      default:
        return null;
    }
  }

  return (
    <div className="question card">
      <div className="card-body">
        <h5 className="card-title">{index}. {question.title}</h5>

        <h6 className="card-subtitle mb-2 text-muted" dangerouslySetInnerHTML={{__html: question.introText}}></h6>
        <div className="card-content">
        { renderType(question) }
        </div>
      </div>
    </div>
  )

}

class Questionnaire extends Component {

  constructor(props) {
    super(props);
    this.questionnaire = props.questionnaire;
    this.optionListMap = props.optionListMap;
    this.onSend = props.onSend;
    this.state = { surveyData: {}, pageNr: 0, progress: 0 };
    this.skippedQuestions = [];
    this.surveyDataAsList = []
    this.resultObject = props.resultObject;

    this.showResult = props.showResult || false;
  }

  setData = (questionId, value ) => {
    /* value = { booleanValue, stringValue, singleOption, multipleOptions } */
    const surveyData = this.state.surveyData;
    surveyData[questionId] = value
    this.surveyDataAsList.splice(0, this.surveyDataAsList.length);
    for (let k in surveyData) { this.surveyDataAsList.push({questionId: k, ...surveyData[k]}) }
    this.setState({surveyData})
  }

  getData = (questionId) => {
    const val = this.state.surveyData[questionId];
    return val;
  }

  sendData = () => {
    this.onSend({
      questionnaireId: this.questionnaire.questionnaireId,
      answers: this.surveyDataAsList.length > 0 ? this.surveyDataAsList : null
    })
  }

  calcProgress = (newPage = null) => {
    return parseInt(Math.ceil( parseFloat(newPage != null ? newPage : this.state.pageNr) / parseFloat(this.questionnaire.questions.length) * 100));
  }

  prevQuestion = () => {
    const that = this;
    const newPage = Math.max(this.state.pageNr-1, 0)
    this.setState({ pageNr: newPage, progress: this.calcProgress(newPage) }, () => {
      const q = that.questionnaire.questions[newPage];
      if (!that.showResult && that.skippedQuestions.includes(q.questionId)) {
        that.skippedQuestions = that.skippedQuestions.filter((s) => s !== q.questionId);
        that.prevQuestion();
      }
    })
  }

  nextQuestion = () => {
    const newPage = Math.min(this.state.pageNr+1, this.questionnaire.questions.length-1)
    const that = this;
    this.setState({ pageNr: newPage, progress: this.calcProgress(newPage) }, () => {
      // check, whether new page has visibility condition
      const q = that.questionnaire.questions[newPage];
      const v = q.visibility;
      const d = that.getData(v?.questionId ?? null);
      const expectedBool = (v?.value === 'true' ? true : false);
      const expectedSingle = v?.value || null
      // console.log(v, d, expectedBool, expectedSingle)
      if (!that.showResult && v && ( (d?.booleanValue !== expectedBool) && (d?.singleOption !== expectedSingle))) {
        that.nextQuestion();
        that.skippedQuestions.push(q.questionId);
      }
    })
  }

  nextButtonAvailable = () => {
    const q = this.questionnaire.questions[this.state.pageNr];
    // text field or filled or not mandatory
    return this.showResult || q.type === 5 || q.mandatory === false || this.state.surveyData[q.questionId] != null;
  }

  render() {
    const optionListMap = this.optionListMap;
    const setData = this.setData;
    const getData = this.getData;
    const that = this;
    const resultObject = this.resultObject;
    const pageNr = this.state.pageNr;
    return (
      <div className="questionnaire">
        <div className="q-header">

          { this.showResult ? <div>Anzahl Antwortbögen: {resultObject.sum} </div>: null }

          <div className="progressWrapper">
            Fortschritt:
            <ProgressBar completed={this.state.progress} bgColor="rgb(20, 80, 20)" transitionDuration="200ms"/>
          </div>

          { this.showResult && pageNr > 0 ? <span><button className="btn btn-primary" onClick={this.prevQuestion}>Zurück</button>&nbsp;</span> : null }
          { this.showResult && pageNr < (this.questionnaire.questions.length-1) ? <span><button disabled={!this.nextButtonAvailable()} className="btn btn-primary" onClick={this.nextQuestion}>Weiter</button>&nbsp;</span> : null }

          <hr/>
        </div>
        <div className="questions">
            { this.questionnaire.questions != null ?
              <div className="question-wrapper">
                  {(this.questionnaire.questions).map(function(question, index){
                    return index === pageNr
                      ? <Question key={ index } index={index + 1} question={question} optionListMap={optionListMap} setData={setData} getData={getData} showResult={that.showResult} resultObject={resultObject}/>
                      : null;
                  })}
              </div>
              :
              <div>Questions missing</div>
            }
        </div>
        <hr/>
        <div className="buttons">
          { !this.showResult && pageNr > 0 ? <span><button className="btn btn-primary" onClick={this.prevQuestion}>Zurück</button>&nbsp;</span> : null }
          { !this.showResult && pageNr < (this.questionnaire.questions.length-1) ? <span><button disabled={!this.nextButtonAvailable()} className="btn btn-primary" onClick={this.nextQuestion}>Weiter</button>&nbsp;</span> : null }
          { !this.showResult && pageNr === (this.questionnaire.questions.length-1) ? <span><button className="btn btn-primary" onClick={this.sendData}>Absenden</button>&nbsp;</span> : null }
        </div>
      </div>
    )
  }
}

export default Questionnaire;