const SERVER_URL = process.env.REACT_APP_SERVER_URL

const FetchService = {
  fetchQuestionnaire: async () => {
    try {
      return await (await fetch(`${SERVER_URL}/v1/questionnaire/1`, { headers: { apiKey: '7772bcfe41bcfe5cbe52bdfeab11428671c'} })).json()
    } catch (e) {
      console.error(e);
    }
  },

  fetchOptionsList: async () => {
    try {
      const list = await (await fetch(`${SERVER_URL}/v1/optionLists`, { headers: { apiKey: '7772bcfe41bcfe5cbe52bdfeab11428671c'} })).json()
      const map = {};
      list.forEach((d) => {
        map[d._id] = d
      })
      return { list, map }
    } catch (e) {
      console.error(e);
    }
  },

  fetchResults: async () => {
    try {
      return await (await fetch(`${SERVER_URL}/v1/surveys/results/1`, { headers: { apiKey: '7772bcfe41bcfe5cbe52bdfeab11428671c'} })).json()
    } catch (e) {
      console.error(e);
    }
  }
}


export default FetchService;