import { Component } from "react";
import { Link } from "react-router-dom";

class Imprint extends Component {
  render() {
    return (
      <div className="App">
        <div className="footer">
          WeNoLa Studie
        </div>
        <div className="content legal">

          <h3>Impressum</h3>
          <div class="imprint">
              <p>app affairs GmbH<br/>
                  Klenzestraße 13<br/>
                  80469 München<br/>
                  Tel: +49 (0)89 - 2379 5795<br/>
                  eMail: &nbsp;info(at)app-affairs.de<br/>
                  Internet: www.app-affairs.de<br/><br/>

                  Gesellschaft mit beschränkter Haftung<br/>
                  Geschäftsführer: Susanne Maurer, Steffen Blass<br/>
                  Registergericht: München HRB 194751<br/>
                  USt-IdNr. DE279312702<br/><br/>
                  Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br/> Susanne Maurer&nbsp;und Steffen Blass<br/><br/>
                  Hiscox Betriebs- und Vermögensschadenshaftpflichtversicherung<br/><br/>
                  Copyright (C) 2022 | app-affairs GmbH<br/>
              </p>
              <hr/>
              <h3>Haftungsbeschränkung</h3>
              <p>Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.</p>
              <h3>Rechtswirksamkeit dieses Haftungsausschlusses</h3>
              <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.<br/><br/>Quelle: Juraforum.de<br/><br/>Facebooktext: Muster von SCHWENKE &amp; DRAMBURG – spreerecht.de</p>
            </div>
        </div>


        <div className="footer">
        <div className="item"><a href="https://www.app-affairs.com" target="_home">app affairs GmbH</a></div>
          <div className="item center"><Link to="/imprint" target="_new">Impressum</Link></div>
          <div className="item right"><Link to="/privacy" target="_new">Datenschutz</Link></div>
        </div>
      </div>

    )
  }
}

export default Imprint;
