import './App.css';
import React, { Component } from 'react';
import Survey from './Survey';
import Imprint from './Imprint';
import Privacy from './Privacy';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

class App extends Component {

  render() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Survey />} />
          <Route exact path="/imprint" element={<Imprint/>} />
          <Route exact path="/privacy" element={<Privacy/>} />
        </Routes>
      </Router>
    )
  }
}

export default App;
