import './App.css';
import React, { Component } from 'react';
import Questionnaire from './components/questionnaire';
import FetchService from './services/fetch.service'
import PushService from './services/push.service'
import { Link } from "react-router-dom";

const QuestionnaireStateLocked = 0;
const QuestionnaireStateUnlocked = 1;
const QuestionnaireStateThankYou = 2;
const QuestionnaireStateError = 3;

class Survey extends Component {

  state = {
    questionnaire: null,
    optionLists: null,
    showThankYouPage: false,
    status: QuestionnaireStateLocked,
  }

  loadData = async() => {
    try {
      const options = await FetchService.fetchOptionsList()
      const questionnaire = await FetchService.fetchQuestionnaire()
      const results = this.showResult ? (await FetchService.fetchResults()) : {}
      this.setState({ questionnaire, optionLists: options.map, results }, () => {
      })
    } catch (e) {
      console.error(e);
    }
  }

  sendData = async(data) => {
    const res = await PushService.sendSurveyData(data);
    if (res === true) {
      this.setState({status: QuestionnaireStateThankYou})
    } else {
      // this.setState({status: QuestionnaireStateError})
      window.alert("Leider ist da etwas schief gelaufen. Bitte versuchen Sie es erneut.")
    }
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const qid = queryParams.get('questionnaire');
    if (qid === 'WLAH2jkKUjb96H') {
      this.setState({status: QuestionnaireStateUnlocked})
    }
    this.showResult = queryParams.get('showResult') != null
    this.loadData()
  }

  renderStatusLocked() {
    return (<div>Locked! Please check your link!</div>)
  }

  renderStatusUnlocked() {
    return (
      this.state.questionnaire != null
      ? <Questionnaire onSend={this.sendData} questionnaire = {this.state.questionnaire} optionListMap = {this.state.optionLists} resultObject={this.state.results} showResult={this.showResult}/>
      : <div>Something missing</div>
    )
  }

  renderStatusThankYou() {
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title" dangerouslySetInnerHTML={{__html: this.state.questionnaire.thankYouTitle}}></h5>
          <h6 className="card-subtitle mb-2 text-muted" dangerouslySetInnerHTML={{__html: this.state.questionnaire.thankYouText}}></h6>
        </div>
      </div>
    )
  }

  renderStatusError() {
    return (<div>Locked! Please check your link!</div>)
  }

  renderContent() {
    switch(this.state.status) {
      case QuestionnaireStateLocked:
        return this.renderStatusLocked();
      case QuestionnaireStateUnlocked:
        return this.renderStatusUnlocked();
      case QuestionnaireStateThankYou:
        return this.renderStatusThankYou();
      case QuestionnaireStateError:
        return this.renderStatusError();
      default:
        return (<div>Something missing</div>)
    }
  }

  renderHeader() {
    return (
      <div className="header">
        <h3 className="title" dangerouslySetInnerHTML={{__html: this.state.questionnaire.title}}></h3>
        <h4 className="subtitle" dangerouslySetInnerHTML={{__html: this.state.questionnaire.introText}}></h4>
      </div>
    )
  }

  render() {
    return (
      <div className="App">

        {
          this.state.questionnaire !== null
          ? this.renderHeader()
          : null
        }

        <div className="content">
          { this.renderContent() }
        </div>
        <div className="footer">
        <div className="item"><a href="https://www.app-affairs.com" target="_home">app affairs GmbH</a></div>
          <div className="item center"><Link to="/imprint" target="_new">Impressum</Link></div>
          <div className="item right"><Link to="/privacy" target="_new">Datenschutz</Link></div>
        </div>
      </div>
    )
  }
}

export default Survey;
