const SERVER_URL = process.env.REACT_APP_SERVER_URL
const axios = require('axios');

const PushService = {
  sendSurveyData: async (data) => {
    try {
      const response = await axios({
        method: "POST",
        headers: { apiKey: '7772bcfe41bcfe5cbe52bdfeab11428671c'},
        url: `${SERVER_URL}/v1/surveys`,
        data,
        withCredentials: false
      })
      console.log(response);
      return true;
    } catch (e) {
      console.error(e);
      return e;
    }
  },
}


export default PushService;